<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-06 14:01:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-07 16:53:24
 * @FilePath: \official_website\src\views\about\About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 关于品信 -->
<template>
  <div id="about">
    <Header></Header>
    <div class="about-content">
      <div class="banner"></div>
      <div class="company-profile">
        <div class="synopsis-box">
          <div class="left wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
            <div class="title">公司简介</div>
            <div class="introduce">
              <p class="first">
                湖南品信生物工程有限公司于2013年由中南大学教授与留学归国博士团队共同创立，旨在为医疗领域提供人工智能精准诊断技术，
                已发展为集<strong>研发、生产、销售、服务于一体</strong>的创新型高新技术企业。
              </p>

              <p class="second">
                以<strong>医疗器械和生物制剂</strong>为主营业务，聚焦细胞形态学智能检测，提供制片-染色-数字化扫描-人工智能分析-大数据远程诊断于一体的全流程医学诊断解决方案，
                涵盖宫颈癌、肺癌、口腔癌等多种肿瘤的早期筛查。目前已在全国大型三甲医院、第三方医学实验室大量投入使用。品信人秉承“品为先，信至上”的理念，携手医疗行业专家、同仁和社会各界人士，
                全力加快人工智能技术在病理检测领域的应用研究与推广，推动病理诊断水平的提高与诊断效率的提升，为成就健康中国而奋斗！
              </p>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/image/about/synopsis-img.png" alt="" />
          </div>
        </div>
      </div>
      <div class="corporate-culture">
        <div class="culture-box">
          <div class="culture-item has-img one">
            <div class="mask"></div>
            <div class="culture-contnet wow animate__animated animate__fadeInUp">
              <div class="title">愿景</div>
              <div class="detail">
                成为一家能不断发现和满足客户需求，充满活力的公司
              </div>
            </div>
          </div>
          <div class="culture-item two">
            <div class="text-box wow animate__animated animate__fadeInUp">
              <div>践行企业文化</div>
              <div>共创行业佳话</div>
            </div>
          </div>
          <div class="culture-item has-img three">
            <div class="mask"></div>
            <div class="culture-contnet wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
              <div class="title">使命</div>
              <div class="detail">
                以创新科技驱动病理诊断，去探索生命中更多地未知，从而创造更加美好的健康生活
              </div>
            </div>
          </div>
          <div class="culture-item has-img four">
            <div class="mask"></div>
            <div class="culture-contnet wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
              <div class="title">价值观</div>
              <div class="detail">
                永远追求真相、本质和原理持续长期付出，不断提升价值
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cover-box">
        <div class="title wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">业务覆盖全国20+省市 300+医院</div>
      </div>
      <div class="development-history">
        <div class="left">
          <div class="course-box">
            <div class="course-img">
              <img src="@/assets/image/about/history-img.jpg" alt="" />
            </div>
            <div class="course-content">
              <div class="area">
                <div class="times">{{ selectedValue }}</div>
                <div class="text wow animate__animated animate__fadeInUp">
                  {{ selectedText }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <scroll-picker
            v-model="selectedValue"
            :options="options"
            @input="handleChange"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    ScrollPicker,
    ScrollPickerGroup,
  },
  data() {
    return {
      selectedValue: "2022-2023年",
      selectedText:
        "加快布局产品多元化战略，快速实现以人工智能为核心的细胞形态学一体化解决方案，布局血液细胞形态学AI分析",
      courseList: [
        {
          name: "2022-2023年",
          value:
            "加快布局产品多元化战略，快速实现以人工智能为核心的细胞形态学一体化解决方案，布局血液细胞形态学AI分析",
        },
        {
          name: "2019-2021年",
          value:
            "与首都医科大学附属北京胸科医院、首都医科大学附属朝阳医院、西京医院、河北省肿瘤医院、湖南省人民医院等全国300余家医院达成深度合作",
        },
        {
          name: "2017-2018年",
          value: "三代产品鹰眼、四代产品吉米娜获批CFDA二类证，产品正式上市",
        },
        {
          name: "2013-2016年",
          value:
            "潜心产品研发，专注人工智能技术在医学诊断领域的应用研究，完成上百万例宫颈癌检测和大数据样本收集",
        },
      ],
      options: ["2022-2023年", "2019-2021年", "2017-2018年", "2013-2016年"],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    handleChange(value) {
      this.selectedValue = value;
      let currentItem = this.courseList.filter((item) => {
        return item.name == value;
      });
      if (currentItem.length != 0) {
        this.selectedText = currentItem[0].value;
      }
    },
  },
};
</script>
    
<style lang="scss">
#about {
  .about-content {
    padding-top: 5rem;
    width: 100%;
    .banner {
      width: 100%;
      height: 50rem;
      background-image: url("../../assets/image/about/banner.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      animation-name: scaleDraw; /*关键帧名称*/
      animation-timing-function: ease; /*动画的速度曲线*/
      animation-duration: 2s; /*动画所花费的时间*/
    }
    @keyframes scaleDraw {
      /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
      0% {
        background-size: 110%;
      }
      100% {
        background-size: 100%;
      }
    }
    .company-profile {
      width: 100%;
      // height: 820px;
      background-image: url("../../assets/image/about/synopsis-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .synopsis-box {
        padding: 6.25rem 8%;
        display: flex;
        .left {
          width: 40%;
          padding: 5rem 3rem;
          .title {
            font-size: 2rem;
            font-weight: 600;
            font-family: "SourceHanSansSC-Medium";
            margin-bottom: 1.25rem;
          }
          .introduce {
            p {
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.5rem;
              strong {
                font-weight: 600;
              }
            }
            .first {
              margin-bottom: 1rem;
            }
            .second {
            }
          }
        }
        .right {
          width: 60%;
          img {
            width: 100%;
          }
        }
      }
    }
    .corporate-culture {
      width: 100%;
      margin-bottom: 8rem;
      // height: 820px;
      background-image: url("../../assets/image/about/culture-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .culture-box {
        padding: 5% 8%;
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        .culture-item {
          width: 48%;
          height: 22rem;
          margin-right: 4%;
          margin-bottom: 2.6rem;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
        .has-img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 2rem 3rem;
          border-radius: 1.5rem;
          position: relative;
          .culture-contnet {
            position: absolute;
            left: 3rem;
            top: 50%;
            z-index: 3;
            padding-top: 1rem;
            width: 65%;
            height: 50%;
            color: #fff;
            .title {
              font-size: 1.6rem;
              font-weight: 600;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .detail {
              font-size: 1.2rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.2rem;
            }
          }
          .mask {
            position: absolute;
            width: 100%;
            height: 50%;
            top: 50%;
            left: 0;
            background-color: rgba($color: #000, $alpha: 0.2);
            border-radius: 0 0 1.5rem 1.5rem;
          }
        }
        .one {
          background-image: url("../../assets/image/about/culture-1.png");
        }

        .three {
          background-image: url("../../assets/image/about/culture-2.png");
        }
        .four {
          background-image: url("../../assets/image/about/culture-3.png");
        }
        .two {
          display: flex;
          justify-content: center;
          align-items: center;
          .text-box {
            div {
              font-size: 2rem;
              font-weight: 600;
              font-family: "SourceHanSansSC-Medium";
              &:first-child {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
    .cover-box {
      width: 100%;
      height: 30rem;
      margin-bottom: 8rem;
      background-image: url("../../assets/image/about/distribution.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .title {
        padding-top: 6rem;
        color: #fff;
        font-size: 3rem;
        text-align: center;
        font-family: "SourceHanSansSC-Medium";
      }
    }
    .development-history {
      width: 92%;
      height: 43.75rem;
      margin-bottom: 6rem;
      // background-color: #ccc;
      border-radius: 0 3.75rem 3.75rem 0;
      display: flex;
      .left {
        width: 80%;
        background-image: url("../../assets/image/about/history-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        .course-box {
          width: 84%;
          height: 40%;
          display: flex;
          .course-img {
            width: 40%;
            img {
              width: 100%;
              border-radius: 1.5rem;
            }
          }
          .course-content {
            width: 60%;
            padding: 3rem;
            .area {
              width: 60%;
              .times {
                font-size: 1.8rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .text {
                font-size: 1.2rem;
                font-family: "OPlusSans3-Regular";
                line-height: 2.2rem;
              }
            }
          }
        }
      }
      .right {
        width: 20%;
        background-color: #406aa1;
        border-radius: 0 3.75rem 3.75rem 0;
        .vue-scroll-picker {
          width: 50%;
          height: 50%;
          margin: 50% auto;
          .vue-scroll-picker-item {
            height: 4em;
            line-height: 4em;
            color: #fff;
            font-size: 1.1rem;
            font-family: "SourceHanSansSC-Medium";
          }
          .vue-scroll-picker-layer .top {
            height: calc(50% - 2em);
            background: linear-gradient(
              180deg,
              #406aa1 10%,
              rgba(64, 108, 159, 0.7)
            );
          }
          .vue-scroll-picker-layer .bottom {
            height: calc(50% - 2em);
            background: linear-gradient(
              0deg,
              #406aa1 10%,
              rgba(64, 108, 159, 0.7)
            );
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>